import React from "react";
import "./PlayButton.scss";

type PlayButtonProps = { label?: string };

const PlayButton = ({ label = "" }: PlayButtonProps) => {
  return (
    <div className="play-button" title={label}>
      <div className="play-button__arrow"></div>
    </div>
  );
};

export default PlayButton;
